/* Profile.css */
.profile-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .profile-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .profile-picture {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin-right: 20px;
  }
  
  .profile-info {
    flex-grow: 1;
  }
  
  h1 {
    margin: 0;
    font-size: 24px;
  }
  
  h2 {
    margin: 5px 0;
    color: #555;
  }
  
  .connect-button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    background-color: #0077b5;
    color: white;
    cursor: pointer;
  }
  
  .connect-button:hover {
    background-color: #005582;
  }
  
  .profile-details {
    margin-top: 20px;
  }
  
  h3 {
    margin-top: 20px;
    font-size: 20px;
    color: #333;
  }
  
  .experience-list, .education-list {
    list-style-type: none;
    padding: 0;
  }
  
  .experience-item, .education-item {
    margin-bottom: 15px;
    background-color: white;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .skills-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .skill {
    background-color: #0077b5;
    color: white;
    padding: 5px 10px;
    border-radius: 15px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  