.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* App.css or main layout CSS */
.main-content {
  margin-left: 220px; /* The width of the sidebar */
  padding: 20px;
  background-color: #f8f9fa;
  min-height: 100vh;
}

/* Adding a top bar margin for the main header */
.header-bar {
  margin-left: 220px; /* Aligning with the expanded main content */
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: calc(100% - 220px);
  z-index: 1000; /* Ensures the header stays on top */
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

