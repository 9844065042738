/* src/ProfilePage.css */
.profile-page {
    padding: 20px; /* Padding for the page */
    background-color: #f0f4f8; /* Light background for contrast */
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: flex-start; /* Align items at the top */
    max-height: 100vh; /* Limit the height to viewport height */
    overflow-y: auto; /* Enable vertical scrolling if needed */
}

.profile-form {
    margin-top: 150px; /* Ensure the form starts below the profile photo */
    width: 100%; 
    max-width: 600px; 
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Optional: Add some shadow for depth */
    padding: 20px; /* Padding for the form */
}

.profile-card {
    background: white; /* White card background */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    width: 100%;
    max-width: 600px; /* Limit card width */
    overflow: hidden; /* Prevent overflow */
    margin: 0 auto; /* Center the card */
    padding-bottom: 20px; /* Add padding to the bottom */
}

.cover-photo-container {
    position: relative;
    width: 100%; /* Full width */
    height: 200px; /* Height for cover photo */
    overflow: hidden;
}

.cover-photo {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Cover the container */
}

.cover-photo-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(221, 221, 221, 0.8); /* Semi-transparent for better visibility */
    height: 100%;
    cursor: pointer;
}

.camera-icon {
    font-size: 50px; /* Adjusted icon size */
    color: #fff; /* White color for contrast */
}

.profile-photo-container {
    position: relative;
    width: 120px; /* Width of profile photo */
    height: 120px; /* Height of profile photo */
    border-radius: 50%; /* Circular profile photo */
    overflow: hidden;
    margin: -60px auto 20px; /* Center align and position above the form */
    border: 4px solid white; /* White border for profile photo */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow */
}

.profile-photo {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Cover the container */
}

.profile-photo-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(221, 221, 221, 0.8); /* Semi-transparent for better visibility */
    width: 100%;
    height: 100%;
    border-radius: 50%; /* Circular placeholder */
    cursor: pointer;
}

.profile-form input,
.profile-form textarea,
.profile-form select {
    width: 100%; /* Full width */
    margin: 10px 0; /* Margin for spacing */
    padding: 12px; /* Padding for comfort */
    border-radius: 5px; /* Rounded corners */
    border: 1px solid #ccc; /* Border styling */
    font-size: 16px; /* Font size */
    box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.save-button {
    background-color: #007bff; /* Button color */
    color: white; /* Text color */
    border: none; /* Remove border */
    border-radius: 5px; /* Rounded corners */
    padding: 12px 15px; /* Padding */
    cursor: pointer; /* Pointer on hover */
    font-size: 16px; /* Font size */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.save-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
}

/* Responsive design adjustments */
@media (max-width: 768px) {
    .profile-photo-container {
        width: 100px; /* Smaller on smaller screens */
        height: 100px; /* Smaller on smaller screens */
    }

    .camera-icon {
        font-size: 40px; /* Smaller icon size */
    }

    .profile-form input,
    .profile-form textarea,
    .profile-form select {
        font-size: 14px; /* Smaller font size on mobile */
    }

    .save-button {
        font-size: 14px; /* Smaller font size on mobile */
    }
}

/* Additional adjustments for larger screens */
@media (min-width: 1200px) {
    .profile-page {
        padding: 40px; /* Increase padding for larger screens */
    }
    
    .profile-card {
        max-width: 800px; /* Increase card width for larger screens */
    }
}
