/* Login.css */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(-135deg, #e06be8, #51cfd3); /* Background gradient from pink to purple */
  color: #fff; /* Text color */
}

.login-box {
  background: rgba(255, 255, 255, 0.15); /* Light background for the form */
  -webkit-backdrop-filter: blur(20px); /* For Safari support */
  backdrop-filter: blur(20px); /* Blurred background */
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.37); /* Shadow effect */
  border-radius: 10px; /* Rounded corners */
  padding: 40px;
  width: 400px;
  text-align: center;
}



h1 {
  color: #00bcd4; /* Title color - Sky Blue */
  margin-bottom: 20px;
}

input {
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border: 1px solid #00bcd4; /* Border color - Sky Blue */
  border-radius: 8px; /* Rounded corners for inputs */
  font-size: 16px;
}

button {
  width: 100%;
  padding: 12px;
  background-color: #8e24aa; /* Button color - Purple */
  border: none;
  border-radius: 8px;
  color: #fff; /* Text color for button */
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #6a1b9a; /* Darker button color on hover - Dark Purple */
}

.error-message {
  color: #ff4d4d; /* Error message color */
  margin-top: 10px;
}

.toggle-form p {
  color: #fff; /* Text color */
}

.toggle-form span {
  color: #00bcd4; /* Highlight color for links - Sky Blue */
  cursor: pointer;
}

.extra-links,
.footer-links {
  margin-top: 20px;
}

.extra-links a,
.footer-links a {
  color: #fff; /* Link color */
  margin: 0 10px;
  text-decoration: none;
  transition: color 0.3s;
}

.extra-links a:hover,
.footer-links a:hover {
  color: #00bcd4; /* Highlight color on hover - Sky Blue */
}
.google-signin {
  margin-top: 20px;
}

.google-signin-button {
  width: 100%;
  padding: 12px;
  background-color: #db4437; /* Google button color - Red */
  border: none;
  border-radius: 8px;
  color: #fff; /* Text color for button */
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.google-signin-button:hover {
  background-color: #c23321; /* Darker Google button color on hover */
}
