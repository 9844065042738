.campaigns-container {
    padding: 20px;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .add-campaign-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .tabs {
    margin-top: 20px;
  }
  
  .tabs button {
    margin-right: 10px;
    padding: 10px 15px;
    border: none;
    cursor: pointer;
  }
  
  .active-tab {
    background-color: #007bff;
    color: white;
  }
  
  .campaigns-list {
    margin-top: 20px;
  }
  
  .campaign-card {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 15px;
  }
  
  .campaign-card h3 {
    margin: 0;
  }
  