/* General Styles */
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #eef1f7;
  overflow: hidden;
}

/* Dashboard Container */
.dashboard-container {
  display: flex;
  width: 100%;
}

/* Sidebar Styles */
.sidebar {
  width: 250px;
  background: linear-gradient(135deg, #007bff, #0056b3);
  color: #fff;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto; /* Make sidebar scrollable if content overflows */
}

.sidebar-header {
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.sidebar-nav a {
  display: block;
  color: #fff;
  padding: 12px 0;
  text-decoration: none;
  border-radius: 8px;
  transition: background 0.3s;
  margin-top: 10px;
}

.sidebar-nav a:hover {
  background: rgba(255, 255, 255, 0.2);
}

/* Main Content Styles */
.main-content {
  margin-left: 250px;
  padding: 20px;
  flex: 1;
  min-height: 100vh;
  margin-top: 60px; /* Adjust for the fixed top bar */
  overflow-y: auto; /* Enable scrolling */
}

/* Top Bar Styles */
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: calc(100% - 300px);
  top: 0;
  left: 250px;
  z-index: 1000;
}

/* Ensure the main content does not overlap with the top bar */
.main-content {
  padding-top: 70px; /* Ensure enough space for the fixed top bar */
}

/* Search Bar */
.search-bar {
  width: 300px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ddd;
}

/* User Info */
.user-info {
  display: flex;
  align-items: center;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

/* Dashboard Overview Styles */
.dashboard-overview {
  display: flex;
  gap: 10px;
  width: 100%;
  margin-top: 20px; /* Adjust for space after the top bar */
  margin-bottom: 30px;
}

.dashboard-card {
  background: linear-gradient(145deg, #ffffff, #e6e9ef);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  flex: 1;
  text-align: center;
  transition: transform 0.3s;
}

.dashboard-card:hover {
  transform: translateY(-5px);
}

/* Campaign Management */
.campaign-management {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

/* Campaign Table */
.campaign-table {
  width: 100%;
  border-collapse: collapse;
}

.campaign-table th,
.campaign-table td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.campaign-table th {
  background-color: #f0f0f0;
}

.campaign-table td button {
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 14px;
  margin-right: 5px;
}

.campaign-table td button:hover {
  background-color: #007bff;
  color: #fff;
}

/* Card Tile */
.card-tile {
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 500px;
  margin: 40px auto;
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
      opacity: 0;
      transform: translateY(10px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

.card-tile h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.card-tile p {
  font-size: 16px;
  margin-bottom: 20px;
}

.card-tile button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.card-tile button:hover {
  background-color: #0056b3;
}
