/* MainLayout.css */
.main-layout {
    display: flex;
    flex-direction: row-reverse; /* Move the sidebar to the right */
    height: 100vh; /* Make the layout take the full viewport height */
}

.sidebar {
    width: 200px; /* Adjust the width of the sidebar */
    background: linear-gradient(135deg, #007bff, #0056b3);
    color: #fff;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 20px;
    overflow-y: auto; /* Enable scrolling for overflow content */
}

.content {
    flex-grow: 1; /* Allow content to take up remaining space */
    margin-right: 200px; /* Adjust based on the sidebar width */
    padding: 20px;
    background-color: #f4f7fc;
    overflow-y: auto; /* Enable scrolling for overflow content */
}
