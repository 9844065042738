/* src/NewCampaign.css */
.new-campaign-container {
    padding: 20px;
    background-color: #f0f4f8; /* Light background for contrast */
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the content */
    max-width: 100%; /* Maximum width for the container */
    margin: 0 auto; /* Center horizontally */
    border-radius: 10px; /* Rounded corners for the container */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

h1 {
    color: #333; /* Darker color for the heading */
    margin-bottom: 20px; /* Space below the heading */
}

.form-grid {
    width: 100%; /* Full width for the form grid */
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns for form fields */
    gap: 20px; /* Space between fields */
}

.form-group {
    display: flex;
    flex-direction: column;
}

.form-group label {
    margin-bottom: 5px; /* Space between label and input */
    font-weight: bold; /* Bold labels */
    color: #555; /* Slightly darker color for labels */
}

.form-group input,
.form-group textarea,
.form-group select {
    padding: 12px;
    border: 1px solid #ccc; /* Border styling */
    border-radius: 5px; /* Rounded corners */
    font-size: 16px; /* Font size */
    box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.form-group input[type="range"] {
    -webkit-appearance: none; /* Standard property for compatibility */
    appearance: none; /* WebKit-specific property */
    width: 100%;
    height: 8px;
    background: #ddd;
    outline: none;
    border-radius: 5px;
  }
  

.form-group input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none; /* Remove default styling */
    appearance: none;
    width: 15px; /* Width of thumb */
    height: 15px; /* Height of thumb */
    background: #007bff; /* Color of thumb */
    border-radius: 50%; /* Round thumb */
    cursor: pointer; /* Pointer on hover */
}

.upload-icon {
    margin-left: 5px; /* Space between text and icon */
    color: #007bff; /* Icon color */
}

.media-preview {
    margin-top: 20px; /* Space above media preview */
    text-align: center; /* Center text in preview */
}

.media-preview video,
.media-preview img {
    border-radius: 5px; /* Rounded corners for media preview */
    margin-top: 10px; /* Space above media preview */
}

button {
    background-color: #007bff; /* Button color */
    color: white; /* Text color */
    border: none; /* Remove border */
    border-radius: 5px; /* Rounded corners */
    padding: 12px 15px; /* Padding */
    cursor: pointer; /* Pointer on hover */
    font-size: 16px; /* Font size */
    margin-top: 20px; /* Space above button */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
}

button:hover {
    background-color: #0056b3; /* Darker blue on hover */
}

/* Responsive design adjustments */
@media (max-width: 768px) {
    .form-grid {
        grid-template-columns: 1fr; /* Single column on small screens */
    }
}

.new-campaign-container {
    max-width: 800px; /* Set a max width for the container */
    margin: 0 auto;
    padding: 20px;
    overflow-y: auto; /* Allow vertical scrolling */
    max-height: 80vh; /* Limit height to allow scrolling */
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .form-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
  
  .full-width {
    grid-column: span 2;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  

  