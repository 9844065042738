/* Sidebar.css */
.sidebar {
  width: 220px;
  background: linear-gradient(135deg, #007bff, #0056b3); /* Gradient background */
  color: #fff;
  height: 100vh;
  position: fixed;
  left: 0; /* Align to the left side */
  top: 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 3px 0 10px rgba(0, 0, 0, 0.1); /* Shadow for subtle effect */
}

.sidebar-logo {
  width: 80px;
  height: auto;
  margin-bottom: 10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.sidebar-header {
  text-align: center;
  margin-bottom: 30px;
}

.sidebar-header h2 {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  color: aliceblue;
}

.sidebar-nav {
  flex-grow: 1; /* Allow navigation to grow */
}

.sidebar-nav a {
  display: block;
  color: #fff;
  padding: 12px;
  text-decoration: none;
  border-radius: 5px;
  margin: 5px 0;
  transition: background 0.3s, transform 0.3s;
}

.sidebar-nav a:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transform: translateX(5px); /* Slight move for interaction effect */
}

.logout-section {
  text-align: center;
  margin-top: 20px;
}

.logout-button {
  background-color: #fff;
  color: #000;
  padding: 10px 15px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background 0.3s;
}

.logout-button:hover {
  background-color: #000;
  color: #fff;
}
